// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "fc_lV";
export var caseStudyVideoReview__bgRings = "fc_J1";
export var caseStudyVideoReview__clientImage = "fc_J3";
export var caseStudyVideoReview__container = "fc_J0";
export var caseStudyVideoReview__logo = "fc_Kd";
export var caseStudyVideoReview__name = "fc_Kf";
export var caseStudyVideoReview__playWrapper = "fc_JS";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "fc_JV";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "fc_JZ";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "fc_JX";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "fc_JY";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "fc_JT";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "fc_JW";
export var caseStudyVideoReview__text = "fc_Kg";
export var caseStudyVideoReview__title = "fc_J2";
export var caseStudyVideoReview__videoYoutube = "fc_Kc";
export var caseStudyVideoReview__wrapper = "fc_J4";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "fc_J7";
export var caseStudyVideoReview__wrapper_videoReviewIx = "fc_Kb";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "fc_J8";
export var caseStudyVideoReview__wrapper_videoReviewRef = "fc_J9";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "fc_J5";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "fc_J6";