// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bZ_k5";
export var caseStudyBackground__lineColor = "bZ_kX";
export var caseStudyHead__imageWrapper = "bZ_kV";
export var caseStudyProjectsSection = "bZ_k6";
export var caseStudyQuote__bgQuote = "bZ_nJ";
export var caseStudyQuote__bgRing = "bZ_k2";
export var caseStudyVideo__ring = "bZ_k8";
export var caseStudy__bgDark = "bZ_kT";
export var caseStudy__bgLight = "bZ_kS";
export var caseStudy__bgLightGray = "bZ_mY";